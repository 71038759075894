import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/lib/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#311B92",
        secondary: "#9C27B0",
        accent: "#82B1FF",
        error: "#b00020",
        info: "#2196F3",
        success: "#009688",
        warning: "#bd9011",
      },
    },
  },
  lang: {
    locales: { de },
    current: "de",
  },
});
