<template>
  <div class="pt-5">
    <v-card class="mx-auto">
      <v-card-title class="pt-4 pb-2 font-weight-regular">
        Notification Service
      </v-card-title>
      <v-card-text class="black-text">
        <slot>
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="title"
              label="Notification Title"
              prepend-icon="mdi-email"
              @keyup.enter="submit"
            ></v-text-field>
            <v-text-field
              v-model="body"
              label="Notification Body"
              prepend-icon="mdi-lock"
              @keyup.enter="submit"
            ></v-text-field>
            <v-text-field
              v-model="img"
              label="Notification img"
              prepend-icon="mdi-image"
              @keyup.enter="submit"
            ></v-text-field>
            <v-btn color="primary" class="white--text" @click="submit">
              Submit
            </v-btn>
          </v-form>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "FormContainer",
  data: () => ({
    title: "",
    body: "",
    img: "",
  }),
  methods: {
    submit() {
      let notification = {
        title: this.title,
        body: this.body,
        img: this.img,
      };
      fetch("/api/send-notification", {
        method: "POST",
        body: JSON.stringify(notification),
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
  },
};
</script>

<style scoped>
.black-text {
  color: #000 !important;
}
</style>
