<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        color="white"
        elevate-on-scroll
        app>
      <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
      >
      </v-app-bar-nav-icon>
      <v-img src="/images/halfbaked.svg" style="height: 32px;" contain></v-img>
    </v-app-bar>
    <v-main class="px-5" style="font-family: 'Nunito', sans-serif;">
      <h1>Hello, this is halfbaked!</h1>
      <p>Welcome to Innovation as a Service.</p>
      <v-card>
        <v-card-title>Notify all users</v-card-title>
        <v-card-actions>
          <v-btn @click="notify">Notify</v-btn>
        </v-card-actions>
      </v-card>
      <Form />
    </v-main>
    <v-bottom-navigation
        app
        color="primary"
        grow
    >
      <v-btn>
        <v-icon color="primary">mdi-home</v-icon>
      </v-btn>
      <v-btn>
        <v-icon>mdi-information</v-icon>
      </v-btn>
      <v-btn>
        <v-icon>mdi-cloud</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import axios from "axios";
import Form from "./components/Form";
export default {
  name: "App",
  components: { Form },
  data: () => ({
    drawer: false,
  }),
  created: function () {
    this.registerServiceWorker();
  },
  methods: {
    notify() {
      fetch("/api/notify-users");
      console.log("notify users");
    },
    registerServiceWorker() {
      if ("serviceWorker" in navigator) {
        window.addEventListener("load", function () {
          navigator.serviceWorker
            .register("/sw.js", {
              scope: "/",
            })
            .then((reg) => {
              console.log("Registration succeeded. Scope is " + reg.scope);
              if (
                !("showNotification" in ServiceWorkerRegistration.prototype)
              ) {
                console.warn("Notifications aren't supported.");
                return;
              }
              if (Notification.permission === "denied") {
                console.warn("The user has blocked notifications.");
                return;
              }
              if (!("PushManager" in window)) {
                console.warn("Push messaging isn't supported.");
                return;
              }
              navigator.serviceWorker.ready.then(
                (serviceWorkerRegistration) => {
                  serviceWorkerRegistration.pushManager
                    .getSubscription()
                    .then((subscription) => {
                      if (!subscription) {
                        axios
                          .get("/api/public-key")
                          .then((response) => {
                            navigator.serviceWorker.ready.then(
                              (serviceWorkerRegistration) => {
                                serviceWorkerRegistration.pushManager
                                  .subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey:
                                      response.data["public-key"],
                                  })
                                  .then((subscription) => {
                                    var key = subscription.getKey
                                      ? subscription.getKey("p256dh")
                                      : "";
                                    var auth = subscription.getKey
                                      ? subscription.getKey("auth")
                                      : "";
                                    const response = {
                                      endpoint: subscription.endpoint,
                                      key: key
                                        ? btoa(
                                            String.fromCharCode.apply(
                                              null,
                                              new Uint8Array(key)
                                            )
                                          )
                                        : "",
                                      auth: auth
                                        ? btoa(
                                            String.fromCharCode.apply(
                                              null,
                                              new Uint8Array(auth)
                                            )
                                          )
                                        : "",
                                    };
                                    fetch("/api/subscribe", {
                                      method: "POST",
                                      body: JSON.stringify(response),
                                      mode: "no-cors",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                    });
                                  });
                              }
                            );
                          });
                        return;
                      }
                    })
                    .catch((err) => {
                      console.warn("Error during getSubscription()", err);
                    });
                }
              );
            })
            .catch((error) => {
              console.error(error);
            });
        });
      }
    },
  },
};
</script>
